<template>
<div>
    <div class="footer_h5">
      <footer-menu :menus="menus"></footer-menu>
      <div class="community">
        <div class="title" @click="goto('community')">
          <Gap :width="16" />
          {{ $t("footer.community") }}
        </div>
        <div class="icon">
          <span
            class="link-icon-common link-icon3"
            @click="jumpto('https://www.facebook.com/Weexglobal')"
          >
          </span>
          <span
            v-if="locale == 'zh-CN' || locale == 'zh-TW'"
            class="link-icon-common link-icon2"
            @click="jumpto('https://www.feixiaohaozh.info/exchange/weex/')"
          >
          </span>
          <span
            v-else
            class="link-icon-common link-icon2-1"
            @click="
              jumpto('https://www.cryptowisser.com/exchange/weex-exchange/')
            "
          >
          </span>
          <span
            class="link-icon-common link-icon1"
            @click="jumpto('https://coinmarketcap.com/exchanges/weex')"
          >
          </span>
          <span
            class="link-icon-common link-icon4"
            @click="jumpto($t('newFooter.link.weex_group'))"
          >
          </span>
          <span
            class="link-icon-common link-icon5"
            @click="jumpto('https://discord.gg/2CFnGbMzbh')"
          >
          </span>
          <span
            class="link-icon-common link-icon6"
            @click="jumpto('https://www.linkedin.com/company/weex-global')"
          >
          </span>
          <span
            class="link-icon-common link-icon7"
            @click="jumpto('https://twitter.com/WEEX_Official')"
          >
          </span>
          <span
            v-if="locale == 'zh-CN' || locale == 'zh-TW'"
            class="link-icon-common link-icon8"
            @click="jumpto('https://medium.com/@Weex')"
          >
          </span>
          <span
            v-else
            class="link-icon-common link-icon8"
            @click="jumpto('https://weexofficial.medium.com/')"
          >
          </span>
        </div>
      </div>
      <div class="footer-opt">
        <div class="opt">
          <div class="title">{{ $t("newFooter.support_service")}}:&nbsp;&nbsp;</div>
          <div class="sub-title" @click="goUrl('https://t.me/weikecs')">
            @weikecs
          </div>
        </div>
        <div class="opt">
          <div class="title">{{ $t("newFooter.business_cooperation") }}:&nbsp;&nbsp;</div>
          <div class="sub-title" @click="goUrl('https://t.me/weikecs')">
            @weikecs
          </div>
        </div>
        <div class="opt">
          <div class="title">
            {{ $t("newFooter.trading_quant") }}:&nbsp;&nbsp;
          </div>
          <div class="sub-title" @click="goUrl('mailto:bd@weex.com')">
            bd@weex.com
          </div>
        </div>
        <div class="opt">
          <div class="title">{{ $t("newFooter.vip_services") }}:&nbsp;&nbsp;</div>
          <div class="sub-title" @click="goUrl('mailto:support@weex.com')">
            support@weex.com
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterMenu from './FooterMenu.vue';
import { mapState } from 'vuex';
import Gap from "~/components/gap";

export default {
  components: {
    Gap,
    FooterMenu
  },
  computed: {
    ...mapState(['locale']),
    isFa(){
      return this.locale.toLowerCase() === 'fa-ir'
    }
  },
  data() {
    return {
      // locale: "",
      menus: []
    };
  },
  watch: {
    locale() {
      this.menus = this.getMenus();
    }
  },
  methods: {
    goto(path) {
      this.$router.push(this.$i18n.path(path));
    },
    gotJoinIn() {
      window.location.href =
        'https://weexsupport.zendesk.com/hc/zh-cn/sections/4487095693593-WEEX%E4%BA%BA%E6%89%8D%E6%8B%9B%E8%81%98%E4%B8%93%E5%8C%BA';
    },
    goUrl(path) {
      window.location.href = path;
    },
    jumpto(path) {
      window.open(path);
    },
    getMenus() {
      return [
        {
          title: this.$i18n.t('newFooter.about'),
          url: '',
          isOpen: false,
          children: [
            {
              title: this.$i18n.t('newFooter.about_us'),
              url: 'aboutus'
            },
            {
              title: this.$i18n.t('newFooter.notice_center'),
              url: this.$i18n.t('newFooter.link.notice_center')
            },
            {
              title: this.$i18n.t('introduction.nav.title2'),
              url: 'how-to-buy'
            },
            {
              title: this.$i18n.t('links.blog'),
              url: 'https://blog.weex.com/'
            },
            {
              title: this.$i18n.t('newFooter.finance-news'),
              url: 'news-report'
            },
            {
              title: this.$i18n.t('spokesperson.nav_short'),
              url: 'spokesperson'
            },
            {
              title: this.$i18n.t('career_opportunities.career_opportunities'),
              url: 'job'
            },
            {
              title: this.$i18n.t('global.title'),
              url: 'community'
            },
            {
              title: this.$i18n.t('new_index.header.title.strategy'),
              url: 'global-strategy'
            }
            // {
            //   title: this.$i18n.t("newFooter.weex_group"),
            //   url: "https://t.me/weex_group",
            // },
            // {
            //   title: this.$i18n.t("link.top-trader-competition"),
            //   url: 'topTraderCompetition',
            // }
          ]
        },
        {
          title: this.$i18n.t('newFooter.compliance'),
          url: '',
          isOpen: false,
          children: [
            {
              title: this.$i18n.t('newFooter.legal_statement'),
              url: this.$i18n.t('newFooter.link.legal_statement')
            },
            {
              title: this.$i18n.t('newFooter.risk_warning'),
              url: this.$i18n.t('newFooter.link.risk_warning')
            },
            {
              title: this.$i18n.t('newFooter.terms'),
              url: this.$i18n.t('newFooter.link.terms')
            },
            {
              title: this.$i18n.t('newFooter.privacy'),
              url: this.$i18n.t('newFooter.link.privacy')
            },
            {
              title: this.$i18n.t('newFooter.whistleblower_notice'),
              url: this.$i18n.t('newFooter.link.whistleblower_notice')
            },
            {
              title: this.$i18n.t('newFooter.policies'),
              url: this.$i18n.t('newFooter.link.policies')
            },
            {
              title: this.$i18n.t('newFooter.law_enforcement'),
              url: this.$i18n.t('newFooter.link.law_enforcement')
            }
          ]
        },
        //服務分類
        // {
        //   title: this.$i18n.t("newFooter.service"),
        //   url: "",
        //   isOpen: false,
        //   children: [
        //     {
        //       title: this.$i18n.t("newFooter.OTC"),
        //       url: this.$i18n.t("newFooter.link.OTC"),
        //     },
        //     {
        //       title: this.$i18n.t("newFooter.download"),
        //       url: "?fromFooterAppDownload=Y",
        //     },
        //     {
        //       title: this.$i18n.t("newFooter.agent"),
        //       url: "topic/agent-introduction-topic",
        //     },
        //     {
        //       title: this.$i18n.t("newFooter.coin_listing"),
        //       url: this.$i18n.t("newFooter.link.coin_listing"),
        //     }
        //   ],
        // },
        {
          title: this.$i18n.t('newFooter.help'),
          url: '',
          isOpen: false,
          children: [
            {
              title: this.$i18n.t('newFooter.support_center'),
              url: this.$i18n.t('newFooter.link.support_center')
            },
            {
              title: this.$i18n.t('wxtPreIco.com_problem'),
              url: 'faq'
            },
            {
              title: this.$i18n.t('new_index.weex_course'),
              url: 'school'
            },
            {
              title: this.$i18n.t('new_index.header.title.official'),
              url: 'official-verification'
            }
            // {
            //   title: this.$i18n.t("newFooter.fees"),
            //   url: this.$i18n.t("newFooter.link.fees"),
            // },
            // {
            //   title: this.$i18n.t("newFooter.trading_rules"),
            //   url: this.$i18n.t("newFooter.link.trading_rules"),
            // }
          ]
        },
        //學習
        // {
        //   title: this.$i18n.t("newFooter.learn"),
        //   url: '',
        //   isOpen: false,
        //   children: [
        //     {
        //       title: this.$i18n.t("newFooter.tutorial_center"),
        //       url: this.$i18n.t("newFooter.link.tutorial_center"),
        //     },
        //     {
        //       title: this.$i18n.t("newFooter.spot"),
        //       url: this.$i18n.t("newFooter.link.spot"),
        //     },
        //     {
        //       title: this.$i18n.t("newFooter.futures"),
        //       url: this.$i18n.t("newFooter.link.futures"),
        //     },
        //     {
        //       title: this.$i18n.t("newFooter.noun_interpretation"),
        //       url: this.$i18n.t("newFooter.link.noun_interpretation"),
        //     }
        //   ]
        // },
        {
          title: this.$i18n.t('common.more'),
          url: '',
          isOpen: false,
          children: [
            {
              title: this.$i18n.t('newFooter.vip_services'),
              url: this.$i18n.t('newFooter.link.vip_services')
            },
            {
              title: this.$i18n.t('newFooter.download'),
              url: 'download'
            },
            {
              title: this.$i18n.t('newFooter.agent'),
              url: 'affiliate'
            },
            {
              title: this.$i18n.t('links.fund'),
              url: 'protectfund'
            },
            {
              title: this.$i18n.t('newFooter.proof_of_reserves'),
              url: 'proof-of-reserves'
            },
            {
              title: this.$i18n.t('newFooter.sitemap'),
              url: 'sitemap'
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.menus = this.getMenus();
    // this.locale = this.$i18n.locale;
  }
};
</script>

<style lang="less">
@media screen and (max-width: 768px) {
  .footer_h5 {
    width: 100%;
    background-color: @black-bg-primary-V2;
    .pxToVwH5(font-size, 26);
    color: #f9f9f9;
    text-align: center;
  }
  .community {
    .title {
      background: @black-bg-primary-V2;
      // .pxToVwH5(padding-left, 30);
      height: 51px;
      line-height: 51px;
      text-align: left;
      font-size: 16px;
      .medium-font();
      font-weight: bold;
    }
    .icon {
      margin: 16px;
      text-align: left;
      display: flex;
      width: auto;
      height: 100%;
      box-sizing: border-box;
      overflow: unset;
      flex-wrap: wrap;
      gap: 16px 16px;
      .link-icon-common {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .link-icon1 {
        background-image: url('~assets/img/footer/v2/CoinMarketCap.svg');
      }
      .link-icon2 {
        background-image: url('~assets/img/footer/v2/Feixiaohao.svg');
      }
      .link-icon2-1 {
        background-image: url('~assets/img/footer/v2/CW2B.svg');
      }
      .link-icon3 {
        background-image: url('~assets/img/footer/v2/Facebook.svg');
      }
      .link-icon4 {
        background-image: url('~assets/img/footer/v2/Telegram.svg');
      }
      .link-icon5 {
        background-image: url('~assets/img/footer/v2/Discord.svg');
      }
      .link-icon6 {
        background-image: url('~assets/img/footer/v2/Linkedin.svg');
      }
      .link-icon7 {
        background-image: url('~assets/img/footer/v2/Twitter.svg');
      }
      .link-icon8 {
        background-image: url('~assets/img/footer/v2/Medium.svg');
      }
    }
  }

  .footer-opt {
    padding: 0 16px;
    padding-bottom: 57px;
    .opt {
      text-align: left;
      display: flex;
      .title,.sub-title {
        font-size: 14px;
        color: @black-text-tertiary-V2;
      }
    }
    .opt + .opt{
      margin-top: 24px;
    }
  }
}
@media screen and (min-width: 769px) {
  .footer_h5 {
    display: none;
  }
}
</style>
