
//获取设置指纹 及设备信息

import Fingerprint2 from './fingerprint2.js'
import UAParser from './ua-parser.js'
import {CRAWLER_UA_TOKENS} from "~/config/global.config";


export const getParser=()=>{
   let parser=new UAParser(navigator.userAgent);
   return {
     os:parser.getOS(),
     browser:parser.getBrowser()
   }
}

/**
 * 检测是否爬虫机器人
 * @return {boolean} True 是 ｜ False 否
 */
export const checkIsCrawlerBot=()=>{
  const CRAWLER_UA_REX = new RegExp(`((${CRAWLER_UA_TOKENS.map(uaStr => uaStr).join('|')}))`)
  const ua = new UAParser(navigator.userAgent).getUA();
  return CRAWLER_UA_REX.test(ua);
}

function calFinger(value){
  return Fingerprint2.x64hash128(value, 31)
}


export const getFinger=(cb)=>{
  if (window.requestIdleCallback) {
    requestIdleCallback(()=> {
        Fingerprint2.getPromise().then((components)=> {
          var values = components.map(function (component) { return component.value })
          var murmur = calFinger(values.join(''));
          if(cb){
            cb(murmur)
          }
        })
    })
    } else {
    setTimeout( ()=> {
        Fingerprint2.getPromise().then((components)=> {
         // console.log(components) // an array of components: {key: ..., value: ...}
         var values = components.map(function (component) { return component.value })
         var murmur = calFinger(values.join(''));
         if(cb){
           cb(murmur)
         }
        })
    }, 500)
    }

}


