import { LocalStorageHandler } from "./LocalStorageHandler";

// 合约pro (除模拟盘之外的币对), 现货的币对
const pairLocal = new LocalStorageHandler('weex::currencyPair');

export const getFuturesPair = () => {
  return pairLocal.get('futuresPair');
};

export const getSpotPair = () => { 
  return pairLocal.get('spotPair');
}


