import { languageKeyMap } from "../config/languageMapping";
// import { faConfig } from "../config/faConfig";
import { faConfig } from "../web-base/config/faConfig.js";

export function matchFaRoute(faConfig, path) {
  const languagePrefix = '/'+languageKeyMap.fa; // 将语言前缀抽取成变量
  // 如果不是波斯语开头直接返回false
  const cleanedPath = path.replace(languagePrefix, '').split('?')[0];

  // 遍历配置，找到第一个匹配的路由
  for (let route of faConfig) {
      // 处理首页
      if (cleanedPath === '') {
          return faConfig.some(route => route.path === '/');
      }
      if (cleanedPath.startsWith(route.path) && route.path !== '/') {
          return true;
      }
  }

  return false;
}

export function matchFaRouteWithFaConfig(path) {
  return matchFaRoute(faConfig, path);
}

export function findFaRouteConfig(path) {
  const languagePrefix = '/'+languageKeyMap.fa; // 将语言前缀抽取成变量
  // 如果不是波斯语开头直接返回null
  const cleanedPath = path.replace(languagePrefix, '').split('?')[0];

  // 遍历配置，找到第一个匹配的路由
  for (let route of faConfig) {
      // 处理首页
      if (cleanedPath === '') {
          return faConfig.find(route => route.path === '/');
      }
      if (cleanedPath.startsWith(route.path) && route.path !== '/') {
          return route;
      }
  }

  return null;
}
