import axios from 'axios';
import HMM from '~/web-base/components/HandmadeMessage/index.js';
import { getCookie, goGlobalPath } from './utils.js';
import {isClient, isProduction, isRC} from './envHelper.js';
import { getAPIUrl } from './urlHelper.js';
import axiosConfig, {forceLoginWhiteList} from '~/config/axiosConfig.js';
import { AxiosRequestConfig } from 'axios';
import sEvent from "./sensorsEvent";
import { getBackendLocaleCode } from './locale.js';

let instance = axios.create({
  timeout: isClient ?  20000 : 200000
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    // 生產環境使用集群，需要根據域名獲取API地址
    // dev/test/staging开发使用網站域名，透過反向代理轉發到API服務器，用以規避跨域問題
    config.baseURL = (isProduction || isRC) ? await getAPIUrl() : process.env.API_BASE_URL;
    // if (isProduction || isRC) {
    //   // if (config.url.startsWith('/v1/')) {
    //     config.baseURL = await getAPIUrl();
    //   // }
    // }else {
    //   config.baseURL = process.env.API_BASE_URL;
    // }
    return await axiosConfig(config);
  },
  err => {
    let i18n = require('../config/request.i18n.conf')();
    HMM({ type: 'error', message: i18n.timeout });
    return Promise.resolve(err);
  }
);

instance.interceptors.response.use(
  data => {
    const duratime = Date.now() - data.config.startTime;
    // 神策埋点事件ajax_response_time
    sEvent('ajax_response_time', data.request.responseURL,{duration: duratime});
    const i18n = require('../config/request.i18n.conf')();
    if (data?.status === 200) {
      const context = isClient && window.$nuxt.context;
      const isLogin = isClient && window.$nuxt.$store.getters.isLogin;
      sEvent('ajax_response_time',  `${data.request.responseURL},code:${data?.data?.code},url:${data.config.url}`,{ext: duratime});
      // 20205 : 登录超时，请重新登录
      // 20906 : Token已经过期请重新登录
      if (['00004', '20906', '20205'].includes(data?.data?.code)) {
        // 如果是获取信息接口 直接返回 空对象
        // 查找强制登录白名单中是否包含与当前请求url匹配的。
        if (forceLoginWhiteList.find(url => data.config.url.indexOf(url) > -1)) {
          // 如果是则强制登录并判断当前是否私有api请求，如果私有API请求则跳转登录
          window.$nuxt.$logout(context).then(() => {
            sEvent('login_timeout_logout', `白名单验证，退出登录，${data.config.url},${process.env.API_PRIVATE_PREFIX}`,{duration: duratime});
            if (data.config.url.includes(`https://${process.env.API_PRIVATE_PREFIX}`)){
              // window.location.href=`/${i18n.locale}login`;
              goGlobalPath('login')
            }
          });
        } else {
          // 如果不是则强制跳转登录
          HMM({ type: 'error', message:i18n.expired });
          window.$nuxt.$logout(context).then(() => {
            // window.location.href=`/${i18n.locale}login`;
            goGlobalPath('login')
          });
        }
        return { data: {} };
      } else if (data?.data?.status === 'error') {
        // 神策埋点事件ajax_error
        sEvent('ajax_error',  data.request.responseURL,{ext: data.data.msg});
        HMM({ type: 'error', message: data.data.msg });
        return { data: {} };
      }
      if (+data.data.code === 600) {
        // 神策埋点事件ajax_error
        sEvent('ajax_error',  data.request.responseURL,{ext: data.data.msg});
        // console.log('0000 data.data.code 600', data)
        // 如果是获取信息接口 直接返回 空对象
        if (data.config.url.indexOf('/v1/user/overview/userinfo')> -1 || data.config.url.indexOf('/v1/mix/index/userSelectedList')> -1) {
          // HMM({ type: 'info', message: data.data.msg });
          window.$nuxt.$logout(context).then(() => {
            // console.log('0000 data.data.code2 600', data)
            //if(isLogin){
              if (data.config.url.includes(`https://${process.env.API_PRIVATE_PREFIX}`)){
              // HMM({ type: 'info', message: data.data.msg });
              console.error('doRequest', data.data.msg);
              // window.location.href=`/${i18n.locale}login`;
              goGlobalPath('login')
            }
          });
        }
        return { data: {} };
      }
    }
    return data;
  },
  err => {
    const eventValue = err && err.response ? err.response.request?.responseURL : err.config?.url;   // 获取异常请求地址
    const errInfo = err && err.response ?  JSON.stringify(err.response?.data) : err.code  // 获取异常信息
    // 神策埋点事件ajax_error
    sEvent('ajax_error', eventValue,{ext: errInfo});

    let i18n = require('../config/request.i18n.conf')();
    if (err.response) {
      switch (err.response.status) {
        case 504:
        case 404:
          HMM({ type: 'error', message: i18n.notfound });
          break;
        case 403:
          HMM({ type: 'error', message: i18n.forbidden });
          break;
        case 500:
          HMM({ type: 'error', message: i18n.unknow });
          break;
        default:
          HMM({ type: 'error', message: i18n.unknow });
          break;
      }
      if (err.response.config && err.response.config.url) {
        console.error(err.response.config.url + ' --- ' + ' status:' + err.response.status + '---' + err.response.statusText);
      }
    } else {
      try {
        if (err?.config?.url) {
          console.log(err?.config?.url + ' : ' + err?.message);
        }
      } catch (err1) {
        console.log(err1);
      }
      if (err.code === 'ECONNABORTED' && err?.message?.includes('timeout')) {
        // 请求超时 处理
        HMM({ type: 'error', message: i18n.timeout });
      }
    }
    return Promise.reject(err.message);
  }
);

export let post = (url, param) => {
  return instance.post(url, param);
};

export const getInstance = (prefix = '/btapi') => {
  // function getLocale(lang) {
  //   switch (lang) {
  //     case 1:
  //       return 'zh_CN';
  //     case 2:
  //       return 'ja_JP';
  //     case 3:
  //       return 'ko_KR';
  //     case 4:
  //       return 'vi_VN';
  //     case 5:
  //       return 'zh_TW';
  //     case 6:
  //       return 'ru_RU';
  //     case 7:
  //       return 'es_ES';
  //     default:
  //       return 'en_US';
  //   }
  // }

  function getHeaders(data) {
    let headers = {};
    let language = getBackendLocaleCode(data.languageType);
    headers['locale'] = language;
    headers['language'] = language;
    // 添加cc token 参数
    let ccheader = getCookie('C-TOKEN-KEY') || '';
    let ccheaderValue = getCookie('C-TOKEN-VALUE') || '';
    if (ccheader && ccheaderValue) {
      headers[ccheader] = ccheaderValue;
    }
    // 登录第二步标识
    if (data.accessToken) {
      headers['u-token'] = data.accessToken;
    }
    // msg 服务 标识
    if (data.vToken) {
      headers['v-token'] = data.vToken;
    }
    if (data.terminalCode) {
      headers['terminalCode'] = data.terminalCode;
    }
    if (data.contentType) {
      headers['Content-Type'] = data.contentType;
    }
    if (data.refreshToken) {
      headers['refresh-token'] = data.refreshToken;
    }
    if(data.locale){
      headers['locale'] = data.locale;
    }
    if(data.language){
      headers['language'] = data.language;
    }
    return headers;
  }

  async function request(method = 'post', url, data = {}, isToast = false) {
    // console.log("182",data);
    // 因时序问题导致i18获取语言异常，改从store中初始化
    let i18n = require('../config/request.i18n.conf')(isClient ? window?.$nuxt?.$i18n?.locale || 'en' : 'en');
    let newurl = prefix + url;

    if (!('languageType' in data)) {
      data = Object.assign({}, data, { languageType: i18n.lang });
    }

    // let headers = getHeaders(data)
    // 在headers新增判斷有沒有headers
    let headers = {};
    if (data.headers) {
      headers = getHeaders(data.headers);
      data = data.data;
    } else {
      headers = getHeaders(data);
      //data = method === 'post' ? data : {};
    }

    return instance
      .request({
        url: newurl,
        method: method,
        headers: headers,
        params: method === 'get' ? data : {},
        data: method === 'post' ? data : {}
      })
      .then(res => {
        let data = res.data;
        // 如果监听toast
        if (isToast) {
          if (+data.code === 0 || Number(data.code) === 200) {
            return Promise.resolve(data);
          } else {
            if (data.msg) {
              HMM(data.msg);
            }
          }
        } else {
          return Promise.resolve(data);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
  return {
    post(url, data, isToast) {
      return request('post', url, data, isToast);
    },
    get(url, data, isToast) {
      return request('get', url, data, isToast);
    }
  };
};

export const baseRequest = getInstance('');
