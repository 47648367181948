export class LocalStorageHandler {
  constructor(localStorageKey) {
    this.localStorageKey = localStorageKey;
    this.data = this._loadData();
  }

  // 加载已有数据
  _loadData() {
    if (!process.client) {
      return {}
    }

    const storedData = localStorage?.getItem(this.localStorageKey) ?? '{}';
    return storedData ? JSON.parse(storedData) : {};
  }

  // 保存数据到localStorage
  _saveData() {
    if (process.client) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.data));
    }
  }

  // 设置键值对
  set(key, value) {
    this.data[key] = value;
    this._saveData();
  }

  // 获取值
  get(key) {
    return this.data[key];
  }
}
