import { LocalStorageHandler } from "~/utils/localStorageHandler/LocalStorageHandler";

// 深度相关
export const headerLocal = new LocalStorageHandler('weex::headerLocal');

/**
 * 设置语言更新异常状态
 * @param {*} value boolean
 */
const setLanguageUpdateExceptionStateLocal = (value) => {
  headerLocal.set('languageUpdateExceptionState', value)
}

/**
 * 获取语言异常状态
 * @returns boolean
 */
const getLanguageUpdateExceptionStateLocal = () => {
  return headerLocal.get('languageUpdateExceptionState')
}

/**
 * 设置当前语言
 * @param {*} value
 */
const setCurrentLanguageLocal = (value) => {
  headerLocal.set('currentLanguage', value)
}

/**
 * 获取当前语言
 * @returns
 */
const getCurrentLanguageLocal = () => {
  return headerLocal.get('currentLanguage')
}




export const headerLocalActions = {
  setLanguageUpdateExceptionStateLocal,
  getLanguageUpdateExceptionStateLocal,
  setCurrentLanguageLocal,
  getCurrentLanguageLocal
}
